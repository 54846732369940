
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';

    export const meta = {
        title: 'Похожие статьи',
        options: {
            head: {
                title: 'Заголовок',
                type: AvailableTypes.editor,
                default: 'Похожие статьи'
            },
            image: {
                title: 'Изображение',
                type: AvailableTypes.image,
                default: {
                    src: '/block-default/SimilarArticles/additional-options-small.jpg'
                }
            },
            articles: {
                title: 'Статьи',
                type: AvailableTypes.pagesMultiselect
            }
        },
        group: AvailableGroups.Тексты,
        additional: true
    };
    @Component({
        components: { ImageComponent, OptionsFieldsInlineEditor }
    })
    export default class SimilarArticles extends Vue {
        @Prop() head;
        @Prop() image;
        @Prop() articles;
        @Prop() component;
    }
