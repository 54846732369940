
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import {
        AvailableGroups,
        AvailableTypes,
        ComponentMeta
    } from '@/components/editor/config/types';

    interface TextBlock extends ComponentMeta {}

    export const meta: TextBlock = {
        title: 'Блог. Дополнительный. Текст',
        options: {
            text: {
                title: 'Текст',
                type: AvailableTypes.editor,
                default:
                    '<p><span style="font-size: 64px;">15%</span> </p><p>Как же получить скидку на заказ?</p>'
            }
        },
        group: AvailableGroups.Тексты,
        additional: true
    };
    @Component({
        components: { OptionsFieldsInlineEditor }
    })
    export default class AdditionalText extends Vue {
        @Prop() text;
        @Prop() component;
    }
