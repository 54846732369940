
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import Form from '@/components/blocks/Form.vue';

    export const meta = {
        title: 'Блог, сайдбар. Форма подписки',
        options: {
            image: {
                title: 'Изображение',
                type: AvailableTypes.image,
                default: {
                    src: '/block-default/FeedbackFormSidebar/mail.svg'
                }
            },
            form: {
                type: AvailableTypes.component,
                componentName: 'Form'
            }
        },
        group: AvailableGroups.Тексты,
        additional: true
    };
    @Component({
        components: { Form, ImageComponent, OptionsFieldsInlineEditor }
    })
    export default class FeedbackFormSidebar extends Vue {
        @Prop() image;
        @Prop() component;
        @Prop() form;
    }
