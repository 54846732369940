
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';

    export const meta = {
        title: 'Блог, сайдбар. Картинка с ссылкой',
        options: {
            text: {
                title: 'Текст',
                type: AvailableTypes.string,
                default: 'Производство свитшотов'
            },
            href: {
                title: 'Ссылка',
                type: AvailableTypes.string,
                default: ''
            },
            targetBlank: {
                title: 'Открывать ссылку в новом окне',
                type: AvailableTypes.boolean,
                default: false
            },
            image: {
                title: 'Изображение',
                type: AvailableTypes.image,
                default: {
                    src: '/block-default/PictureWithLink/sweatshirt.svg'
                }
            }
        },
        group: AvailableGroups.Сайдбар,
        additional: true
    };
    @Component({
        components: { ImageComponent, OptionsFieldsInlineEditor }
    })
    export default class PictureWithLink extends Vue {
        @Prop() text;
        @Prop() href;
        @Prop() targetBlank;
        @Prop() image;
        @Prop() component;
    }
